import React from "react";
import styled from "styled-components";

import { pallete } from "./Pallete";

// Import components
import phone from "../img/phone.png"
import ver from "../img/ver.png"
import team from "../img/team.png"
import card from "../img/card.png"
import eye from "../img/eye.png"

const HowWork = () =>{

    return(
        <Container>
            <h1>How It Works</h1>

            <Item>
                <h2>
                    Registration
                </h2>
                <div>
                    <img src={phone} alt="icon"/>
                    <p>Call QPay with the details of your transaction, the parties and goods involved, as well as the
                        expectations for payment and goods transfer deadlines. This will also involve the discussion of fees for our
                        service.
                    </p>
                </div>
            </Item>


        
            <Item>
                <h2>
                    Verification
                </h2>
                <div>
                    <img src={ver} alt="icon"/>
                    <p>Our team will review your application and verify your account, ensuring compliance with
                    international regulations and financial laws – making sure everything is in order before you begin your
                    transaction. At this point, we will introduce you to our experts in the appropriate areas of your transaction
                    and allocate warehouse and port facilities for the goods. Furthermore, we have a comprehensive database of
                    suppliers and customers that we use to evaluate your deal based on their previous performance and our
                    internal rating. This will help us determine how reliable your potential customer/supplier is and aid us in
                    advising you on your trade’s prospects.
                    </p>
                    
                </div>
            </Item>

        
        
            <Item>
                <h2>
                    Goods/Payment Evaluation
                </h2>
                <div>
                    <img src={team} alt="icon"/>
                    <p>Our team will send a group of specialists to each prospective client in the
                        transaction to verify the quality, quantity, and condition of goods to make sure both parties are receiving
                        what they were promised.
                    </p>
                    
                </div>
            </Item>

       
       
            <Item>
                <h2>
                    Payment 
                </h2>
                <div>
                    <img src={card} alt="icon"/>
                    <p>Once the inspection of goods and preparation for the dispatch are agreed upon, the buyer will
                    send a payment to an intermediary account controlled by Qpay, which will then hold the funds while logistics
                    for the goods take place. Only once all goods are delivered to the location agreed by both parties, checked by
                    the Qpay allocated specialist, and verified by the buyer, will the payment be released from the intermediary
                    account to the seller.
                    </p>
                    
                </div>
            </Item>

        
        
            <Item>
                <h2>
                    Track and Manage
                </h2>
                <div>
                <img src={eye} alt="icon"/>
                    <p>Monitor your transaction history, manage recipients, and track payment and shipping
                        status with ease by talking to our professional trade specialists. Plus, if your deal involves different countries
                        and currencies, don't worry! We'll handle the payments in the correct currency.
                    </p>
                    
                </div>
            </Item>

        

    </Container>

    )
}


const Container = styled.div`
    position:relative;
    background:${pallete.BackGround};
    display:flex;
    flex-direction:column;
    align-items:space-between;
    justify-content:center;
    padding:6rem 5rem 5rem;
    h1,h2,p{
        color:${pallete.White};
    }
    @media(max-width:620px){
        h1,h2{
            text-align:center;
        }
    }
    @media(max-width:560px){
        padding:6rem 2rem 5rem;
        h1{
            font-size:4rem;
        }
    }
    @media(max-width:420px){
        h1{
            font-size:3.5rem;
        }
    }
`
const Item = styled.div`
    margin:5rem 0;
    h2{
        margin-bottom:1.5rem;
        font-weight:300;
    }
    div{
        display:flex;
        align-items:center;
        p{
            padding:0 2rem;
            font-size:1.5rem;
            font-weight:300;
        }
        img{
            width:90px;
        }
    }
    @media(max-width:620px){
        div{
            flex-direction:column;
            img{
                width:60px;
            }
            p{
                padding:2rem 0;
                margin-top:2rem;
            }
        }

    }
`

export default HowWork;