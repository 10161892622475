import React from "react";
import styled from "styled-components";

import { pallete } from "./Pallete";

// Import components
import risk from "../img/risk.jpg"


const AboutRisk = () =>{

    return(
        <Container>
            <div>
            <p>Qpay Limited can help you mitigate the risks associated with complex supply chains and ensure
                prompt payment for the goods you sell. Furthermore, Qpay offers buyers the assurance that
                their funds are secure until the goods promised are in transit, and their quality is verified.
            </p>
            </div>
            <div>
                <img src={risk} alt="team"/>
            </div>

        </Container>
    )
}


const Container = styled.div`
    position:relative;
    padding:12rem 12rem ;
    display:flex;
    align-items: center;
    justify-content: center;
    justify-content:space-between;
    border-bottom: 5px solid ${pallete.LogoPink};
    div{
        width:45%;
        img{
            width:100%;
        }
    }
    p{
        z-index:10;
        font-size:2rem;
        font-weight:300;
        color:${pallete.BackGround};
    }
    @media(max-width:980px){
        flex-direction:column;
        div{
            width:100%;
            img{
                margin-top:5rem;
            }
        }
    }
    @media(max-width:780px){
        padding:12rem 5rem;
    }
    @media(max-width:460px){
        padding:12rem 3rem;
        div{
            p{
                font-size:1.6rem;
            }
        }
    }
`

export default AboutRisk;