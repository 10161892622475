import { Routes, Route ,useLocation } from 'react-router-dom'
// Import Styles
import GlobalStyle from "./components/GlobalStyle"

// Import components
import Navigation from "./components/Navigation"
import Home from "./pages/Home";
import About from "./pages/About";
import Why from "./pages/Why";
import How from "./pages/How";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <GlobalStyle/>
      <Navigation/>
        <ScrollToTop />
        <Routes location={location} key={location.pathname}>
            <Route path="/"  element={<Home/>}/>
            <Route path="/about"  element={<About/>}/>
            <Route path="/why"  element={<Why/>}/>
            <Route path="/how"  element={<How/>}/>
            <Route path="/contact" element={<Contact/>}/>
          </Routes>
        <Footer/>
    </div>
  );
}

export default App;
