import React from "react";
import styled from "styled-components";

import { pallete } from "./Pallete";

// Import components

const HomeQuestions = () =>{

    return(
        <Container>
            <Questions>
               <ul>
                    <li>
                        Are you tired of delayed payments from customers? 
                    </li>
                    <li>
                        Do you feel like you are being pressured into buying goods before they have been
                        checked?
                    </li>
                    <li>
                        Do you want to ensure you are paying for exactly what you were promised?
                    </li>
                    <li>
                        Would you like to avoid extortionate bank charges for poor trade guarantees? 
                    </li>
                    <li>
                        Do you need consistent low-cost goods release and payment security?
                    </li>
               </ul>
            </Questions>
            <Answer>
                <h2>
                    QPay services are here to fulfil all your needs
                </h2>
            </Answer>
        </Container>
    )
}


const Container = styled.div`
    position:relative;
    display:flex;
    background:${pallete.BackGround};
    div{
        width:45%;
        padding:12rem 4rem 12rem 12rem;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        li,h2{
            color:${pallete.White};
        }
    }
    @media(max-width:1240px){
        div{
            padding:12rem 4rem 12rem 4rem;
        }
    }
    @media(max-width:860px){
        flex-direction:column-reverse;
        div{
            width:100%;
            h2{
                text-align:center;
            }
        }
    }
    @media(max-width:480px){
        div{
            padding:10rem 2rem 10rem 2rem;
        }
        div{
            h2{
                font-size:2.2rem;
            }
            li{
                font-size:1.6rem;
            }
        }
    }
`
const Questions = styled.div`
    font-size:2rem;
    font-weight:300;
    ul{
        li{
            list-style-type: none;
            margin-bottom:1rem;
            
        }
    }

`
const Answer = styled.div`
    position:relative;
    background:${pallete.LogoBlue};
    h2{
        font-size:3rem;
        font-family: 'Arvo', serif;
    }

`

export default HomeQuestions;