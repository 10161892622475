import React from "react";
// import styles
import styled from "styled-components";
import { pallete } from "./Pallete";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../img/qpayLogo.png"

const BurgerMenu = () =>{
    const [menuActive, SetMenuActive] = useState(false);
    return(
        <>
        <BurgerBox>
        <WrapperLogo>
            <Link to="/" id="logo"><img src={logo} alt="logo"/></Link>
            
        </WrapperLogo>
            <ButtonBurger  onClick={ () => SetMenuActive(!menuActive) } >
                <p style={{
                color: menuActive ?  pallete.LogoOrange : pallete.LightGray,
                }}>Menu</p>
            </ButtonBurger>
            <BrugerContainer style={{
                height:  menuActive ?  "100vh" : "100vh",
                opacity: menuActive ?  "1" : "0",
                display: menuActive ?  "block" : "none",
                }}>
                <UlBurgenCont>
                    <img src={logo} alt="logo"/>
                    <li >
                        <Link onClick={ () => SetMenuActive(!menuActive) } style={{ color: pallete.LogoBlue}} to="/" >Home</Link>                     
                    </li>
                    <li >
                        <Link onClick={ () => SetMenuActive(!menuActive) } style={{ color: pallete.LogoPink}}to="/about" >About Us</Link>                       
                    </li>
                    <li>
                        <Link onClick={ () => SetMenuActive(!menuActive) } style={{ color: pallete.LogoOrange}} to="/why" >Why Choose QPay</Link>                              
                    </li>
                    <li >
                        <Link  onClick={ () => SetMenuActive(!menuActive) }style={{ color: pallete.LogoBlue}} to="/how" >How It Works</Link>                       
                    </li>
                    <li >
                        <Link  onClick={ () => SetMenuActive(!menuActive) }style={{ color: pallete.LogoPink}} to="/contact" >Contact</Link>                      
                    </li>
                </UlBurgenCont>
            </BrugerContainer>
        </BurgerBox>
        </>
    )
}

const BurgerBox = styled.div`
    box-sizing:border-box;
    display:none;
    position: sticky !important;
    @media (max-width:780px){
        display:block;    
    }
    z-index:150 !important;
`
const ButtonBurger = styled.button`
    position: fixed;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    align-items: center;
    top: 3rem;
    right: 2rem;
    cursor: pointer;
    border: none;
    background: none;
    z-index:150 !important;
    -webkit-tap-highlight-color:  transparent;
    p{
        color:${pallete.LightGray};
        font-size:1.5rem;
        font-family: 'Arvo', serif;
        font-weight:400;
        transition: all .35s;
        height:40px;
        width:40px;
        &:focus{
            color:${pallete.LogoOrange};
            transition: all .35s ease-in-out;
        }
        a:hover:after{
            color:${pallete.LightGray};
            transition: all .35s ease-in-out;
        }

        
    }
    &:hover{
        background:none !important;
        -webkit-tap-highlight-color:  transparent;
    }
`



const BrugerContainer = styled.div`  
    background-color:${pallete.BackGround};
    transition: 0.5s all ease;
    @media (max-width:780px){
        display:block;
        position: fixed !important;
        width:100%;    
    }
`


const UlBurgenCont = styled.ul`
    margin-top:80px;
    display:flex
    flex-direction:column;
    list-style: none;
    text-align:center;
    width:100%;
    img{
        width:50%;
        height:auto;
        margin-bottom:30px;
    }
    li{
        justify-content:center;
        text-align:center;
        font-weight:200;
        cursor:pointer;
        padding:2rem 0;
        a{
            font-size:2rem;
            font-weight:400;
            margin-bottom:25px;
            transition:0.3s;
            text-decoration: none;
            -webkit-tap-highlight-color:  transparent;
            &:hover{
                background:none !important;
                -webkit-tap-highlight-color:  transparent;
            }
        }

        -webkit-tap-highlight-color:  transparent;
        &:hover{
            background:none !important;
            -webkit-tap-highlight-color:  transparent;
        }
    }
    li:hover{
        a{
            color:${pallete.LogoBlue};
        }
    } 

`
const WrapperLogo =styled.div`
    position: fixed;
    transform: translate(-50%, -50%);
    top: 3rem;
    left:4rem;
    a{

        -webkit-tap-highlight-color:  transparent;
            &:hover{
                background:none !important;
                -webkit-tap-highlight-color:  transparent;
            }
        img{
            width:60px;
        }
    }
`


export default BurgerMenu;
