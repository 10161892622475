import React from "react";

// import {motion } from "framer-motion"
// import { stagger , childFade} from "../anim";
// import { useScroll } from "./useScroll";

import styled from "styled-components";
import { pallete } from "./Pallete";
import coins from "../img/coins.jpg"
import StyledButton from "./StyledButton";

const LetsWork = () =>{ 
    // const [element, controls] = useScroll({ layoutEffect: false });
    return(
        <Wrapper>
            <Container>
                    <h2><span>QPay</span> services are here to fulfil all  <span>your needs</span></h2>
                    <div>
                        <StyledButton name="Contact" url="/contact" />
                    </div>
            </Container>
        </Wrapper>
    )
}
const Wrapper = styled.div`
    position:relative;
    padding:5rem 0;
    background-image: url(${coins});
    background-size: cover;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); 
    }
`
const Container = styled.div`
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin:0 auto;
    width:90%;

    h2{
        color:${pallete.White};
        font-family: 'Lato', sans-serif;
        font-size:4rem;
        text-align:center;
        font-weight:600;
        
        span{
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        background-color: ${pallete.White};
        background-image:linear-gradient(to right, ${pallete.LogoBlue}, ${pallete.LogoPink}, ${pallete.LogoOrange});
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
    }
    }
    >div{
        margin:3rem auto;
       
    }

    @media(max-width:780px){
        text-align:center;
        width:90%;
    }
    @media(max-width:640px){
        h2{
            font-size:3.96rem;
        }
    }
    @media(max-width:480px){
        h2{
            font-size:3rem;
            line-height:40px;
        }
    }
    @media(max-width:440px){
        h2{
            font-size:2.6rem;
        }
    }
`
    


export default LetsWork;