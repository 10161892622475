import React from "react";
import styled from "styled-components";

import { pallete } from "./Pallete";

// Import components
import port from "../img/port.jpg"


const AboutTop = () =>{

    return(
        <Container>
            <p>Quick Payments Limited, or Qpay, is an expert broker and goods purchasing service provider
                specialising in international wholesale trade transactions. Our mission is to simplify and secure
                your trades, allowing you to confidently expand your business and customer base without the
                hassle of worrying about safely securing payments or goods from your international clients.
            </p>

        </Container>
    )
}


const Container = styled.div`
    position:relative;
    display:flex;
    flex-direction:column;
    padding:12rem 12rem ;
    background-image: url(${port});
    background-size: cover;
    border-bottom: 5px solid ${pallete.LogoBlue};
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); 
    }
    p{
        z-index:10;
        font-size:2rem;
        font-weight:300;
        color:${pallete.White};
    }
    @media(max-width:780px){
        height:100vh;
        padding:12rem 5rem;
        justify-content:center;
    }
    @media(max-width:460px){
        padding:12rem 3rem;
        p{
            font-size:1.6rem;
        }
    }
`

export default AboutTop;