import React from "react";
import styled from "styled-components";
// import Components
import HomeEnterance from "../components/HomeEnterance";
import HomeQuestions from "../components/HomeQuestions"
import HomeExplain from "../components/HomeExplain"
import HomeExplainEnd from "../components/HomeExplainEnd"
import HomeQpay from "../components/HomeQpay"

const Home = () =>{

    return(
        <Container>   
          <HomeEnterance/>
          <HomeQuestions/>
          <HomeExplain/>
          <HomeExplainEnd/>
          <HomeQpay/>
        </Container>
    )

}

const Container = styled.div`
    position: relative;
    overflow: hidden;
`;

export default Home;