import React from "react";
import styled from "styled-components";

import { pallete } from "./Pallete";

// Import components


const HomeExplain = () =>{

    return(
        <Container>
           
            <Answer>
                <h2>
                    Secure payment and goods check, release, dispatch service 
                </h2>
            </Answer>
            <Questions>
              <p>
                 As an experienced player in the international wholesale
                trading and secure payments industry, Qpay understands
                the complexities that sometimes make operating in this
                space frustrating.
                </p>
                <p>
                    That's why we've created a reliable and secure payment
                    and goods release service that's solely dedicated to
                    facilitating seamless money transfers for your international
                    goods supply transactions.
                </p>
            </Questions>
        </Container>
    )
}


const Container = styled.div`
    position:relative;
    display:flex;
    background:${pallete.BackGround};
    div{
        width:45%;
        padding:12rem 4rem 12rem 12rem;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p,h2{
            color:${pallete.White};
        }
    }
    @media(max-width:1240px){
        div{
            padding:12rem 4rem 12rem 4rem;
        }
    }
    @media(max-width:860px){
        flex-direction:column;
        div{
            width:100%;
            h2{
                text-align:center;
            }
        }
    }
    @media(max-width:480px){
        div{
            padding:10rem 2rem 10rem 2rem;
        }
        div{
            h2{
                font-size:2.2rem;
            }
            p{
                font-size:1.6rem;
            }
        }
    }

`
const Questions = styled.div`
    font-size:2rem;
    font-weight:300;
        p{
            margin-bottom:1rem;        
        }

`
const Answer = styled.div`
    position:relative;
    background:${pallete.LogoPink};
    
    h2{
        font-size:3rem;
        width:100%;
        z-index:10;
        font-family: 'Arvo', serif;
    }
`

export default HomeExplain;