import React from "react";
import styled from "styled-components";
// import Components

import WhyChoose from "../components/WhyChoose"
import WhyBlock1 from "../components/WhyBlock1"
import WhyBlock2 from "../components/WhyBlock2"
import WhyBlock3 from "../components/WhyBlock3"
import WhyBlock4 from "../components/WhyBlock4"


const About = () =>{

    return(
        <Container>   
            <WhyChoose/>
            <WhyBlock1/>
            <WhyBlock2/>
            <WhyBlock3/>
            <WhyBlock4/>
        </Container>
    )

}

const Container = styled.div`
    position: relative;
    overflow: hidden;
`;

export default About;