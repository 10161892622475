import React from "react";
import styled from "styled-components";

import { pallete } from "./Pallete";

// Import components
import security from "../img/security.jpg"

const WhyBlock1 = () =>{

    return(
        <Container>
           
            <Questions>
              <p>
              We prioritize the safety of your transactions,
            utilizing advanced encryption and fraud prevention
            measures as well as in-person checks and balances
            that ensure you are getting the goods or payment you
            were promised.
                </p>
            </Questions>
            <Answer>
                <h2>
                    Security
                </h2>
            </Answer>
        </Container>
    )
}


const Container = styled.div`
    position:relative;
    display:flex;
    background:${pallete.BackGround};
    div{
        width:45%;
        padding:12rem 4rem;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p,h2{
            color:${pallete.White};
        }
    }

    @media(max-width:860px){
        flex-direction:column-reverse;
        div{
            width:100%;
            h2{
                text-align:center;
            }
        }
    }
    @media(max-width:480px){
        div{
            padding:10rem 2rem;
        }
        div{
            p{
                font-size:1.6rem;
            }
        }
    }
`
const Questions = styled.div`
    font-size:2rem;
    font-weight:300;
    background:${pallete.LogoPink};
        p{
            margin-bottom:1rem;        
        }

`
const Answer = styled.div`
    position:relative;
    background-image: url(${security});
    background-size: cover;
    z-index:10;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3px);
    }
    h2{
        text-align:center;
        font-size:3.5rem;
        width:100%;
        z-index:10;
        font-family: 'Arvo', serif;
    }
`

export default WhyBlock1;