import React from "react";
import styled from "styled-components";

import { pallete } from "./Pallete";

// Import components
import containers from "../img/containers.jpg"
import StyledButton from "./StyledButton";

const HomeEnterance = () =>{

    return(
        <Container>
            <h1>Welcome to QPay</h1>
            <h2>Your Trusted Broker in International Wholesale
                Goods and Commodities Trading
            </h2>
            <StyledButton name="How It Works" url="/how"/>
        </Container>
    )
}


const Container = styled.div`
    position:relative;
    display:flex;
    flex-direction:column;
    padding:12rem 12rem ;
    height:100vh;
    background-image: url(${containers});
    background-size: cover;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); 
    }
    h1{
        margin-bottom:2rem;
        color:${pallete.White};
        z-index:10;
       
    }
    h2{
        width:70%;
        color:${pallete.White};
        font-weight:300;
        z-index:10;
        margin-bottom:2rem;
    }
    @media(max-width:860px){
        padding:12rem 4rem ;

    }
    @media(max-width:660px){
        padding:14rem 4rem ;
        h1{
            font-size:4rem;
        }
        h2{
            font-size:2rem;
            width:100%;
        }
        
    }
    @media(max-width:480px){
        padding:14rem 2rem ;
        text-align:center;
        align-items:center;
        justify-content:center;
        h1{
            font-size:3rem;
        }
        h2{
            font-size:1.8rem;
            width:100%;
        }
    }
    @media(max-width:340px){
        h1{
            font-size:2.6rem;
        }
        h2{
            font-size:1.6rem;
        }
    }
    
`

export default HomeEnterance;