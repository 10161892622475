import React from "react";
import styled from "styled-components";
// import Components
import HowWork from "../components/HowWork";
import HowImages from "../components/HowImages";





const How = () =>{

    return(
        <Container> 
            <HowWork/>
            <HowImages/>
        </Container>
    )

}

const Container = styled.div`
    position: relative;
    overflow: hidden;
`;

export default How;