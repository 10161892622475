import React from "react";
import styled from "styled-components";

import { pallete } from "./Pallete";

// Import components
import tanker from "../img/tanker.jpg"
import logo from "../img/qpayLogo.png"


const WhyChoose = () =>{

    return(
        <Container>
           <div>
                <h1>Why Choose</h1>
           </div>
            <div>
                <img src={logo} alt="logo"/>
            </div>
        </Container>
    )
}


const Container = styled.div`
  position: relative;
  display: flex;
  padding: 12rem 12rem;
  background-image: url(${tanker});
  background-size: cover;
  align-items: center;
  border-bottom:5px solid ${pallete.LogoOrange};
  
  div {
    z-index: 10;
    width: 50%;
    display:flex;
    justify-content:center;
    
    h1 {
      z-index: 10;
      font-size: 6rem;
      font-weight: 300;
      color: ${pallete.White};
    }
    
    img {
      width: 350px;
    }
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  @media(max-width:860px){
    flex-direction:column;
    text-align:center;
    div{
      width:100%;
      img{
        margin-top:3rem;
        width:260px;
      }
    }
  }
  @media(max-width:780px){
    padding: 0rem 2rem;
    height:100vh;
    justify-content:center;
  }
  @media(max-width:480px){
   div{
      h1{
        font-size:4.6rem;
      }
      img{
        width:220px;
        margin-top:2rem;
      }
   }
  }
  @media(max-width:380px){
   div{
      h1{
        font-size:3.6rem;
      }
      img{
        width:180px;
        
      }
   }
  }
`

export default WhyChoose;