import React from "react";
import styled from "styled-components";

import { pallete } from "./Pallete";


const AboutRange = () =>{

    return(
        <Container>
            <Electr>
                <h2>Our expertise extends to a broad range of industries, including the following:</h2>
                <ul>
                    <li>
                        Electronic goods (both professional and consumer).
                    </li>
                    <li>
                        Specialist electronic components and equipment.
                    </li>
                    <li>
                        Computer hardware, digital memory products, and computer components.
                    </li>
                    <li>
                        Building materials and specialist structure components.
                    </li>
                    <li>
                        Cars and automotive components.
                    </li>
                    <li>
                        Aviation industry parts and aeroplane components.
                    </li>
               </ul>
            </Electr>
            <Materials>
                <h2>Plus, commodities such as:</h2>
                <ul>
                    <li>
                        Metals, rare metals and metal materials, including metals for the recycling industry (excluding precious metals).
                    </li>
                    <li>
                        Plastics and plastic materials, including virgin granules and recycled materials.
                    </li>
                    <li>
                        Woods and woods materials.
                    </li>
                </ul>
            </Materials>
        </Container>
    )
}


const Container = styled.div`
    position:relative;
    display:flex;
    div{
        width:45%;
        padding:12rem 4rem 12rem 12rem;
        display: flex;
        flex: 1;
        flex-direction: column;
        font-size:2rem;
        font-weight:300;
        border-bottom: 5px solid ${pallete.LogoPink};
        ul{
            li{
                list-style-type: none;
                margin-bottom:1rem;
                color:${pallete.White};
            }
        }
        h2{
            margin-bottom:1.5rem;
            color:${pallete.White};
        }
    }
    @media(max-width:1240px){
        flex-direction:column;
        div{
            width:100%;
            padding:12rem;
        }
    }
    @media(max-width:780px){
        div{
            padding:12rem 5rem;
        }
       
    }
    @media(max-width:460px){
        div{
            padding:12rem 3rem;
            h2{
                font-size:2.2rem;
            }
            ul{
                li{
                    font-size:1.6rem;
                }
            }
        }
    }
    @media(max-width:390px){
        div{
            h2{
                font-size:2rem;
            }
        }
    }
`

const Electr = styled.div`
    background:${pallete.LogoBlue};
`
const Materials = styled.div`
    background:${pallete.LogoOrange};

`

export default AboutRange;