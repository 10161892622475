import React from "react";
import styled from "styled-components";

import { pallete } from "./Pallete";

// Import components


const HomeExplainEnd = () =>{

    return(
        <Container>
            <Questions>
              <p>
                    With Qpay, you can focus on growing your business while
                    we take care of your financial transaction security and
                    goods release. Trust us to make your transaction
                    experience smooth and hassle-free, whether you're a
                    buyer or seller.
                </p>
                <p>
                    Do you want to secure payments for your goods quickly
                    and safely or ensure that you receive the goods promised
                    before releasing payment?
                </p>
            </Questions>
            <Answer>
                <h2>
                    QPay is here to make things simple, safe, and stress-free for you
                </h2>
            </Answer>
           
        </Container>
    )
}


const Container = styled.div`
    position:relative;
    display:flex;
    background:${pallete.BackGround};
    div{
        width:45%;
        padding:12rem 4rem 12rem 12rem;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p,h2{
            color:${pallete.White};
        }
    }
    @media(max-width:1240px){
        div{
            padding:12rem 4rem 12rem 4rem;
        }
    }
    @media(max-width:860px){
        flex-direction:column-reverse;
        div{
            width:100%;
            h2{
                text-align:center;
            }
        }
    }
    @media(max-width:480px){
        div{
            padding:10rem 2rem 10rem 2rem;
        }
        div{
            h2{
                font-size:2.2rem;
            }
            p{
                font-size:1.6rem;
            }
        }
    }
`
const Questions = styled.div`
    font-size:2rem;
    font-weight:300;
        p{
            margin-bottom:1rem;        
        }

`
const Answer = styled.div`
    position:relative;
    background:${pallete.LogoOrange};
    
    h2{
        font-size:3rem;
        width:100%;
        z-index:10;
        font-family: 'Arvo', serif;
    }
`

export default HomeExplainEnd;