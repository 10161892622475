import { createGlobalStyle } from "styled-components"



const GlobalStyle = createGlobalStyle`

    *{
        margin:0;
        padding:0;
        box-sizing:border-box;
    }
    html{
        @media (max-width: 1700px){
            font-size: 75%;
        }
        @media (max-width: 1050px){
            font-size: 67%;
        }   
    }
    a,p,h1,h2,h3,h4,li{
        font-family: 'Lato', sans-serif;
    }
    h1{
        font-size: 6rem;
        font-family: 'Arvo', serif;
        font-weight:400;
    }
    h2{
        font-size: 2.5rem;
        font-family: 'Arvo', serif;
    }
    h3{
        font-size:2rem;
        font-family: 'Arvo', serif;
    }
    h4{
        font-weight: bold;
        font-size: 1.5rem;    
        font-family: 'Arvo', serif;
    }
    a{
        font-size: 1.5rem;
    }
   
    ::-webkit-scrollbar {
        width: 5px; 
        background: linear-gradient(to bottom, #3c81c0, #e43253);
    }

    ::-webkit-scrollbar-thumb {
        background: #ef9824; 
        border-radius: 5px;
    }

   
    scrollbar-color: #ef9824 linear-gradient(to bottom, #3c81c0, #e43253);

    
    ::-webkit-scrollbar-thumb:hover {
        background: #ffab32;
    }

    
    scrollbar-color: #ffab32 #ef9824;

`
export default GlobalStyle;