import React from "react";
import styled from "styled-components";
import { pallete } from './Pallete';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
// import {motion} from 'framer-motion'

const StyledButton = ({name , url}) =>{
    const { pathname } = useLocation();
    return(
        <Container>
            <Link className="cta" to={url} id="logo">
                <span>{name}</span>
            </Link> 
        </Container>
    )

}

const Container = styled.div`
    a{
        position: relative;
        display: flex;
        justify-content:center;
        align-items:center;
        width:200px;
        height: 40px;
        line-height: 40px;
        font-size: 1.5rem;
        border-radius:12px;
        font-weight:300;
        text-decoration: none;
        color: ${pallete.White};
        border: 2px solid ${pallete.White};
        letter-spacing: 2px;
        text-align: center;
        transition: all .35s;
        @media(max-width:340px){
            width:160px; 
        }
    }

    a span{
    position: relative;
    z-index: 2;
    }

    a:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-radius:12px;
    background: linear-gradient(to right, ${pallete.LogoBlue}, ${pallete.LogoPink}, ${pallete.LogoOrange});
    transition: all .35s ease-in-out;

    }

    a:hover:after{
    width: 100%;
    }
`

export default StyledButton;