import React from "react";
import styled from "styled-components";
// import Components

import AboutTop from "../components/AboutTop"
import AboutRisk from "../components/AboutRisk"
import AboutRange from "../components/AboutRange"

const About = () =>{

    return(
        <Container>   
            <AboutTop/>
            <AboutRisk/>
            <AboutRange/>
        </Container>
    )

}

const Container = styled.div`
    position: relative;
    overflow: hidden;
`;

export default About;