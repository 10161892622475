import React from "react";

// import {motion } from "framer-motion"
// import { stagger , childFade} from "../anim";
// import { useScroll } from "./useScroll";

import styled from "styled-components";
import { pallete } from "./Pallete";
import flags from "../img/flags.jpg"
import port from "../img/port.jpg"
import risk from "../img/risk.jpg"
const HowImages = () =>{ 

    return(
        <Wrapper>
                    <ImageWrapper>
                        <img src={flags} alt="flags"/>
                        <img src={port} alt="port"/>
                        <img src={risk} alt="team"/>
                    </ImageWrapper>
    
        </Wrapper>
    )
}
const Wrapper = styled.div`
  background-color:${pallete.BackGround};
`

const ImageWrapper = styled.div`
    display:flex;
    justify-content:space-evenly;
    img{
        width:33.3333%;
        filter: brightness(70%);
    }
`
    


export default HowImages;