import React from "react";

import styled from "styled-components";

import { pallete } from "../components/Pallete";
import colors from "../img/colors.jpg"



const Contact = () =>{

    return(
        <>
            <Info>
                <WrapperContant>
                <h1>Qpay: Where Speed Meets Security.</h1>
                    <div>
                        <h3>Thank you for choosing QPay as your trusted broker for international wholesale trade.</h3>
                    </div>
                    <div>
                        <ul>
                            <li>Qpay Limited</li>
                            <li>Vicarage House</li>
                            <li>58-60 Kensington Church Str</li>
                            <li>London</li>
                            <li>W8 4DB</li>
                            <li>www.qpaylimited.com</li>
                            <li>enquiry@qpaylimited.com<button onClick={() => {navigator.clipboard.writeText("enquiry@qpaylimited.com") ; alert("Press okay to copy! :)")}}>Copy</button> </li>                        
                            <li>+447727033933<button onClick={() => {navigator.clipboard.writeText("+447727033933") ; alert("Press okay to copy! :)")}}>Copy</button> </li>    
                        </ul>
                    </div>
                </WrapperContant>
            </Info>
            <MapWrapper>
                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1241.7405529116138!2d-0.19337560059104777!3d51.50438900457571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sVicarage%20House%2058-60%20Kensington%20Church%20Street%20London%20W8%204DB!5e0!3m2!1sen!2suk!4v1698142757960!5m2!1sen!2suk&amp;hl=en"title="Map" loading="lazy"></iframe>
            </MapWrapper>
        </>
    )
    
}



const Info = styled.div`
    position:relative;
    z-index:1;
    padding: 10rem 10rem;
    overflow:hidden;
    display: grid;
    place-items: center;
    background-color:${pallete.BackGround};
    background-image: url(${colors});
    background-size: cover;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2); 
    }
    
    >div{
        width:90%;
        margin:0 2rem;
        z-index:10;
        text-align:center;
        h1{
            font-size:5rem;
            padding-bottom:7rem;
            font-weight:300;
            color:${pallete.White};
            text-align:center;
        }
        h3{
            width:100%;
            font-size:1.8rem;
            padding-bottom:1rem;
            color:${pallete.White};
            font-weight:500;
        }
        ul{
            color:${pallete.White};       
            font-size:1.5rem;
            list-style-type: none;
            text-align:center;
            li{
                margin:1rem 0;
                font-weight:300;
                button{
                    cursor:pointer;
                    padding:0.3rem 0.8rem;
                    margin:0.5rem 0 0 1rem;
                    font-family: 'Lato', sans-serif;
                    border:none;
                    font-weight:300;
                    text-decoration: none;
                    color: ${pallete.White};
                    border: 2px solid ${pallete.White};
                    border-radius:12px;
                    background:transparent;
                    letter-spacing: 2px;
                    text-align: center;
                }
            }
        }
    }
    @media(max-width:1200px){
        padding: 15rem 1rem;
    }
    @media(max-width:1050px){
        div{
            h3{
                margin-bottom:2rem;
            }
        }
    }
    @media(max-width:860px){
        div{
            width:90%;
            margin:0rem auto;
            h3{
               font-size:3rem;
               line-height:40px;
            }
            ul{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
               li{
                    margin:0.5rem 0;
               }
            }
        }
        
    }
    @media(max-width:680px){
        padding: 10rem 1rem;
        div{
            h3{
                font-size:2.5rem;
            }
        }
    }

    @media(max-width:600px){
        div{
            width:98%;
            h1{
                font-size:4rem;
            }
            h3{
                font-size:2rem;
                line-height:30px;
            }
        }
    }
    @media(max-width:500px){
        div{
            h1{
                font-size:3rem;
            }
            h3{
                font-size:1.6rem;
            }
        }
    }
    @media(max-width:400px){
       div{
            h1{
                padding-bottom:3rem;
            }
       }
        
    }
`
const WrapperContant = styled.div`
    
`
const MapWrapper = styled.div`
    position:relative;
    z-index:1;
    overflow: hidden;
    iframe{
        width:100%;
        height:550px;
        border:none;
        margin-bottom: -10px;
    }
    @media(max-width:1300px){
        iframe{
            width:100vw;
            height:350px;
            
        }
    }
`


export default Contact;